<template>
  <div class="d-flex">
    <dx-util-text-box v-model="costFormData.asin" placeholder="ASIN" :width="180" class="mr-half" :show-clear-button="true" />
    <dx-util-number-box :ref="`textbox-cost-${row.data.id}`" v-model="costFormData.cost"
      :min="0" :width="150"
      :step="0"
      placeholder="Cost"
      @enter-key="onEnterKey"
      @focusOut="handleFocusOut"
    >
      <dx-text-box-button :name="`cost-${row.data.id}`" :options="textBoxButtonOptions" location="after" />
    </dx-util-number-box>
  </div>
</template>

<script>
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import amazonReportService from '@/http/requests/reports/amazonReportService.js'
import { Notify } from '@robustshell/utils/index'

export default {
  components: {
    'dx-text-box-button': DxTextBoxButton,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      costFormData: {
        itemId: this.row.data.id,
        cost: null,
        asin: this.row.data.asin,
        amzMonthlyProfitId: this.row.data.amzMonthlyProfitId,
      },
    }
  },
  computed: {
    textBoxButtonOptions() {
      return {
        icon: 'save',
        type: 'success',
        onClick: () => this.onUpdateCost(),
      }
    },
  },
  mounted() {
    if (this.row.data.asin === undefined || this.row.data.asin === null) {
      this.costFormData.asin = this.findAsinInString(this.row.data.sku)
    }
  },
  methods: {
    onUpdateCost() {
      const payload = []
      payload.push(this.costFormData)
      amazonReportService.updateItemCost(payload).then(() => {
        this.$emit('emit-cost-updated')
        Notify.success('Cost is successfully updated.')
      })
    },
    onEnterKey() {
      this.onUpdateCost()
    },
    handleFocusOut() {
      this.$emit('emit-cost-entered', this.costFormData)
    },
    findAsinInString(str) {
      const parts = str.split('_')
      const asin = parts.filter(part => this.isValidAsin(part))[0]
      return asin
    },
    isValidAsin(value) {
      if (!value || value === '') {
        return false
      }
      if (value !== '' && value !== null && typeof value !== 'undefined') {
        const v = value.toString().toUpperCase()
        const regex = /^B[A-Z0-9]{9}$/gm
        const isbnxRegex = /^[0-9]{9}X$/gm
        if (regex.test(v) || isbnxRegex.test(value)) {
          return true
        }
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped></style>
